
import { Component, Vue, Watch } from "vue-property-decorator";
import { ICompteurData, IMembreData, ICentreData } from "@/api/types";
import { FileStatus } from "@/utils/test";
import { getCompteurs, getCompteurFile, deleteCompteur } from "@/api/compteurs";
import { getCentres } from "@/api/centres";
import { getMembres } from "@/api/membres";
import { base64toBlob } from "@/utils/files";

@Component({
  name: "Fichiers",
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        published: "success",
        draft: "gray",
        deleted: "danger",
      };
      return statusMap[status];
    },
    parseTimeDDN: (timestamp: string) => {
      return new Date(timestamp).toLocaleDateString();
    },
    parseTimeFile: (timestamp: string) => {
      return new Date(timestamp).toLocaleString();
    },
  },
})
export default class extends Vue {
  //#region Datas
  FileStatus = FileStatus;
  private listCompteurs: ICompteurData[] = [];
  private listCompteursLoading = false;

  private listCentres: ICentreData[] = [];
  private listCentresLoading = true;

  private listMembresCache: IMembreData[] = [];
  private listMembres: IMembreData[] = [];
  private listMembresLoading = true;

  private timerId: number = 0;

  // Query_string de la requete
  private listQuery = {
    criterias: "",
  };

  // Critères de recherches
  private BasicCriterias: any = {};

  // Options du datepicker début
  private pickerOptionsDebut = {
    shortcuts: [
      {
        text: "Aujourd'hui",
        onClick(picker: any) {
          picker.$emit("pick", new Date());
        },
      },
      {
        text: "Il y a une semaine",
        onClick(picker: any) {
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          picker.$emit("pick", start);
        },
      },
      {
        text: "Il y a 1 mois",
        onClick(picker: any) {
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          picker.$emit("pick", start);
        },
      },
      {
        text: "Il y a 3 mois",
        onClick(picker: any) {
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 3);
          picker.$emit("pick", start);
        },
      },
      {
        text: "Il y a 6 mois",
        onClick(picker: any) {
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 6);
          picker.$emit("pick", start);
        },
      },
    ],
  };

  // Options du datepicker fin
  private pickerOptionsFin = {
    shortcuts: [
      {
        text: "Aujourd'hui",
        onClick(picker: any) {
          picker.$emit("pick", new Date());
        },
      },
      {
        text: "Dans une semaine",
        onClick(picker: any) {
          const start = new Date();
          start.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
          picker.$emit("pick", start);
        },
      },
      {
        text: "Dans 1 mois",
        onClick(picker: any) {
          const start = new Date();
          start.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
          picker.$emit("pick", start);
        },
      },
      {
        text: "Dans 3 mois",
        onClick(picker: any) {
          const start = new Date();
          start.setTime(start.getTime() + 3600 * 1000 * 24 * 30 * 3);
          picker.$emit("pick", start);
        },
      },
      {
        text: "Dans 6 mois",
        onClick(picker: any) {
          const start = new Date();
          start.setTime(start.getTime() + 3600 * 1000 * 24 * 30 * 6);
          picker.$emit("pick", start);
        },
      },
    ],
  };
  //#endregion

  //#region Lifecycle hooks
  created() {
    // Récupération des centres
    this.getCentres();
    // Récupération des membres
    this.getMembres();
    // Préparation des critères de recherche (lance une recherche)
    let start = new Date();
    start = new Date(start.getFullYear(), 0, 1);
    this.BasicCriterias = {
      SearchingCriteria: "",
      DateStart: start,
      DateEnd: new Date(),
      SortingColumn: "",
      SortingDirection: "",
      Limit: -1,
      CurrentPage: -1,
      RequestTotalPages: false,
      MembreId: "",
      CentreId: "",
    };
  }
  //#endregion

  //#region Computed

  //#endregion

  //#region Methods
  // Chargement de la liste des centres
  private async getCentres() {
    this.listCentresLoading = true;
    try {
      const { data } = await getCentres(null);
      this.listCentres = data.items;
    } catch (error) {
    } finally {
      this.listCentresLoading = false;
    }
  }

  // Chargement de la liste des membres
  private async getMembres() {
    this.listMembresLoading = true;
    try {
      const { data } = await getMembres(null);
      this.listMembresCache = data.items;
      this.listMembres = this.listMembresCache;
    } catch (error) {
    } finally {
      this.listMembresLoading = false;
    }
  }

  // Chargement de la liste des fichiers
  private async getList() {
    this.listCompteursLoading = true;
    try {
      // Hack pour passer des valeurs non null au centre id et membre id
      let criteriasString = JSON.stringify(this.BasicCriterias)
        .replace('"MembreId":""', '"MembreId":0')
        .replace('"CentreId":""', '"CentreId":0');

      this.listQuery.criterias = btoa(criteriasString);
      const { data } = await getCompteurs(this.listQuery);
      this.listCompteurs = data.items;
    } catch (error) {
    } finally {
      this.listCompteursLoading = false;
    }
  }

  // Chargement du fichier PDF d'un compteur
  private async viewPDF(index: any, row: any) {
    try {
      var id = new Date().getTime();
      var myWindow = window.open("", id.toString());
      const { data } = await getCompteurFile(row.idCompteur);
      if (data.items.length == 1 && myWindow != null) {
        const blob = base64toBlob(data.items[0], "application/pdf");
        const blobUrl = URL.createObjectURL(blob);
        myWindow.location.href = blobUrl;
        myWindow.focus();
      } else {
        myWindow?.close();
        this.$message({
          type: "error",
          message: "Fichier non trouvé",
        });
      }
    } catch (error) {
    } finally {
    }
  }

  // Suppression d'un compteur
  private async deleteCompteur(index: any, row: any) {
    try {
      this.$confirm(
        "Veuillez confirmer la suppression du fichier.",
        "Attention",
        {
          confirmButtonText: "Supprimer",
          cancelButtonText: "Annuler",
          type: "warning",
        }
      )
        .then(async () => {
          const { data } = await deleteCompteur(row.idCompteur);
          this.listCompteurs = this.listCompteurs.filter(function (item) {
            return item.idCompteur !== data;
          });
          this.$message({
            type: "success",
            message: "Fichier supprimé",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Suppression annulée",
          });
        });
    } catch (error) {
    } finally {
    }
  }

  // Au changement de centre, on actualise la liste des membres disponibles
  private selectCenterChanged(selectedValue: any) {
    if (selectedValue !== "") {
      this.listMembres = this.listMembresCache.filter(function (item) {
        return item.centreId == selectedValue;
      });
    } else {
      this.listMembres = this.listMembresCache;
    }
    this.BasicCriterias.MembreId = "";
  }
  //#endregion

  //#region Watchers
  // Au changement sur un des critères de recherche, on relance la récupération des compteurs
  @Watch("BasicCriterias", { deep: true })
  private onCriteriasChanged() {
    window.clearTimeout(this.timerId);
    this.timerId = window.setTimeout(this.getList, 750);
  }
  //#endregion
}
