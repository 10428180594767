import request from '@/utils/request'

export const getCompteurs = (params: any) =>
  request({
    url: '/compteurs',
    method: 'get',
    params
  })

export const getCompteurFile = (idcompteur: number) =>
  request({
    url: '/compteurs/' + idcompteur.toString() + '/file',
    method: 'get',
  })

export const deleteCompteur = (idcompteur: number) =>
  request({
    url: '/compteurs/' + idcompteur.toString(),
    method: 'delete',
  })
